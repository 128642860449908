import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of as observableOf } from 'rxjs/observable/of';
import { Credentials } from 'src/app/modules/models/credentials';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl = `${env.apiHost}:${env.apiPort}/api/login`;
  baseToken = `${env.apiHost}:${env.apiPort}/api/token`;

  constructor(private hc: HttpClient) { }

  start(c: Credentials): Observable<boolean> {
    return new Observable(observer => {
      this.hc.post(this.baseUrl, c, {
        observe: 'response',
        responseType: 'text'
      }).subscribe((response: HttpResponse<any>) => {
        if (response.status === 200) {
          console.log(response.headers);
          window.localStorage.setItem('_session', response.headers.get('s-t'));
          observer.next(true);
        }
        return({unsubscribe() {}});
      }, (error: HttpErrorResponse) => {
          observer.error(error);
      });
    });
  }

  stop(): Observable<boolean> {
    const t = window.localStorage.getItem('_session') || 'undefined';
    if (t === 'undefined') {
      return observableOf(true);
    }
    return new Observable(observer => {
      this.hc.delete(`${this.baseToken}/${t}`, {
        headers: {
          's-t': t
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((response: HttpResponse<string>) => {
        if (response.status === 200) {
          window.localStorage.removeItem('_session');
          observer.next(true);
        } else {
          observer.next(false);
        }
        return({unsubscribe() {}});
      }, error => {
        observer.error(error);
      });
    });
  }
}
