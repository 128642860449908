import { Injectable } from '@angular/core';
import { User } from 'src/app/modules/models/user';
import { BehaviorSubject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class CurrentService {
  user = new BehaviorSubject<User>(null);
  user$ = this.user.asObservable();

  constructor() { }

  changeUser(u: User) {
    this.user.next(u);
  }
}
