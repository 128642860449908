import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Token } from 'src/app/modules/models/token';
import { TokenService } from 'src/app/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private ts: TokenService, private r: Router) { }

  getToken(tkn: string): Promise<Token> {
    return new Promise((resolve, reject) => {
      this.ts.single(tkn).subscribe(t => {
        resolve(t);
      });
    });
  }

  canActivate(): Promise<boolean> {
    const self = this;
    return new Promise(async (resolve, reject ) => {
      const session = localStorage.getItem('_session') || '';
      if (! session) {
        self.r.navigate(['login']);
        resolve(false);
      } else {
        try {
          const t = await self.getToken(session);
          resolve(true);
        } catch (err) {
          self.r.navigate(['login']);
          resolve(false);
        }
      }
    });
  }
}
