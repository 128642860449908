import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Token } from '../modules/models/token';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  baseUrl = `${env.apiHost}:${env.apiPort}/api/token`;
  constructor(private hc: HttpClient) { }

  single(token: string): Observable<Token> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<Token>(`${this.baseUrl}/${token}`, {
      headers: {
        's-t': session
      }
    });
  }
  delete(token: string): Observable<boolean> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    const self = this;
    return new Observable(observer => {
      self.hc.delete(`${this.baseUrl}/${token}`, {
        headers : {
          's-t': session
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          observer.next(true);
        } else {
          observer.error(res.status);
        }
        return {unsubscribe() {}};
      });
    });
  }
}
