import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StudentLesson } from '../modules/models/student-lesson';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentLessonService {
  private baseUrl = `${env.apiHost}:${env.apiPort}/api/student_books`;
  private baseUrlAddendum = '/lessons';

  constructor(private hc: HttpClient) { }

  getList(bid: string): Observable<StudentLesson[]> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<StudentLesson[]>(`${this.baseUrl}/${bid}${this.baseUrlAddendum}`, {
      headers : {
        's-t': session
      }
    });
  }

  saveNew(lesson: StudentLesson): Observable<StudentLesson> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.post<StudentLesson>(`${this.baseUrl}/${lesson.idBook}${this.baseUrlAddendum}`, lesson, {
      headers : {
        's-t': session
      }
    });
  }

  update(lesson: StudentLesson): Observable<StudentLesson> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.put<StudentLesson>(`${this.baseUrl}/${lesson.idBook}${this.baseUrlAddendum}/${lesson._id}`, lesson, {
      headers : {
        's-t': session
      }
    });
  }

  // bid = book id
  single(bid: string, id: string): Observable<StudentLesson> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<StudentLesson>(`${this.baseUrl}/${bid}${this.baseUrlAddendum}/${id}`, {
      headers : {
        's-t': session
      }
    });
  }

  delete(bid: string, id: string): Observable<boolean> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    const self = this;
    return new Observable(observer => {
      self.hc.delete(`${this.baseUrl}/${bid}${this.baseUrlAddendum}/${id}`, {
        headers : {
          's-t': session
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          observer.next(true);
        } else {
          observer.error(res.status);
        }
        return {unsubscribe() {}};
      });
    });
  }
}
