import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';
import { AdminGuard } from 'src/app/guards/admin.guard';

const routes: Routes = [
  {
    path: 'schools',
    loadChildren: './modules/school-module/school-module.module#SchoolModuleModule',
    canActivate: [LoggedInGuard, AdminGuard]
  },
  {
    path: 'books',
    loadChildren: './modules/book/book.module#BookModule',
    canActivate: [LoggedInGuard, AdminGuard]
  },
  {
    path: 'book-types',
    loadChildren: './modules/book-type/book-type.module#BookTypeModule',
    canActivate: [LoggedInGuard, AdminGuard]
  },
  {
    path: 'student-books',
    loadChildren: './modules/student-book/student-book.module#StudentBookModule',
    canActivate: [LoggedInGuard, AdminGuard]
  },
  {
    path: '',
    loadChildren: './modules/home/home.module#HomeModule',
    canActivate: [LoggedInGuard, AdminGuard]
  },
  {
    path: 'admin',
    loadChildren: './modules/admin/admin.module#AdminModule',
    canActivate: [LoggedInGuard, AdminGuard]
  },
  {path: 'login', loadChildren: './modules/login/login.module#LoginModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
