import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { StudentLesson, LessonBlock } from 'src/app/modules/models/student-lesson';
import { StudentLessonService } from 'src/app/services/student-lesson.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit, AfterViewInit {
  lesson: StudentLesson;
  blocks: String[] = [];

  constructor(
    private ls: StudentLessonService,
    public ref: MatDialogRef<LookupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StudentLesson
    ) {
      this.lesson = data;
    }

  ngOnInit() {
  }

  async ngAfterViewInit() {
    try {
      if (this.lesson.blocks) {
        for (const block of this.lesson.blocks) {
          if (block.type === 'lesson_block') {
            const b = block as LessonBlock;
            this.blocks.push(`<div class="${b.type}">`);
            this.blocks.push(b.body);
            this.blocks.push('</div>');
          }
        }
      }
    } catch (err) {

    }
  }
}
