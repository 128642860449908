import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AdminTeacherBook';

  constructor(private r: Router, private ls: LoginService) {}

  OnSchoolClick() {
    this.r.navigate(['schools']);
  }
  OnBookClick() {
    this.r.navigate(['books']);
  }
  OnStudentBookClick() {
    this.r.navigate(['student-books']);
  }
  OnUserClick() {
    this.r.navigate(['admin', 'users']);
  }
  OnBookTypesClick() {
    this.r.navigate(['book-types']);
  }

  private logout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.ls.stop().subscribe(r => {
        resolve(r);
      });
    });
  }

  async OnUserLogout() {
    try {
      const loggedOut = await this.logout();
      if (loggedOut) {
        this.r.navigate(['login']);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
