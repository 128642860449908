import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/modules/models/user';
import { CurrentService } from 'src/app/services/current.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private cs: CurrentService ) { }

  canActivate(): boolean {
    return this.cs.user.value ? this.cs.user.value.role === 'admin' : false;
  }
}
