import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule} from '@angular/material';
import { MatButtonModule, MatIconModule, MatSelectModule, MatDialogModule} from '@angular/material';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';
import { AdminGuard } from 'src/app/guards/admin.guard';
import { LookupComponent} from 'src/app/lookup/dialogs/lookup/lookup.component';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AppComponent,
    LookupComponent,
    EscapeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    HttpClientModule,
    DragDropModule,
    MatTabsModule
  ],
  entryComponents: [
    LookupComponent
  ],
  providers: [LoggedInGuard, AdminGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
